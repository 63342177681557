import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ListResponseData,
  Order,
  Param,
  Wholesaler,
  WholesalerDistributionCenter,
  WholesalerDistributionCenterInput,
  WholesalerDistributionCenterWithAddress,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    wholesaler,
    order,
    orderBy,
    page,
    query,
  }: {
    wholesaler: Wholesaler;
    page?: number;
    order: Order;
    orderBy: keyof WholesalerDistributionCenterWithAddress;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (wholesaler) {
      params.push({ key: "wholesaler_id", value: wholesaler?.id });
    }
    return useQuery<ListResponseData<WholesalerDistributionCenterWithAddress>>({
      cacheTime: 0,
      queryKey: [
        "wholesaler_distribution_centers",
        wholesaler,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "wholesaler_distribution_centers",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<WholesalerDistributionCenter>({
      cacheTime: 0,
      queryKey: ["wholesaler_distribution_centers", id],
      queryFn: () =>
        handleDetail({ id, baseUrl: "wholesaler_distribution_centers" }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (
    wholesalerDistributionCenterInput: WholesalerDistributionCenterInput,
  ) => {
    const navigate = useNavigate();
    return useMutation<WholesalerDistributionCenter>({
      mutationFn: () =>
        handleSave({
          baseUrl: "wholesaler_distribution_centers",
          input: wholesalerDistributionCenterInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () =>
        handleDelete({ baseUrl: "wholesaler_distribution_centers", id }),
      onError: handleQueryError(navigate),
    });
  },
};
export default methods;
