import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  ListResponseData,
  Member,
  MemberInput,
  MemberSearchQueryProps,
  MemberWithAddress,
  Order,
  Param,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

async function searchMembers({
  businessUnit,
  query,
}: MemberSearchQueryProps): Promise<Member[]> {
  try {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("query", query);
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    const data: Member[] = await client
      .get("members/search", {
        searchParams,
      })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy: keyof MemberWithAddress;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({ key: "business_unit_id", value: businessUnit.id });
    }
    return useQuery<ListResponseData<MemberWithAddress>>({
      cacheTime: 0,
      queryKey: ["members", businessUnit, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "members",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSearch: ({ businessUnit, query }: MemberSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Member[]>({
      queryKey: ["members", businessUnit, query],
      queryFn: () => searchMembers({ businessUnit, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Member>({
      cacheTime: 0,
      queryKey: ["members", id],
      queryFn: () => handleDetail({ id, baseUrl: "members" }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (memberInput: MemberInput) => {
    const navigate = useNavigate();
    return useMutation<Member>({
      mutationFn: () => handleSave({ baseUrl: "members", input: memberInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ id, baseUrl: "members" }),
      onError: handleQueryError(navigate),
    });
  },
};
export default methods;
