import camelcaseKeys from "camelcase-keys";
import { snakeCase } from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BulkEdit,
  BulkEditInput,
  ListResponseData,
  MetaPagination,
  QueryProps,
} from "../types";
import client from "./client";
import { handleQueryError } from "./handler";

async function fetchBulkEdits({
  businessUnit,
  order,
  orderBy,
  page,
}: QueryProps): Promise<ListResponseData<BulkEdit>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy));
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }

    const response: { data: BulkEdit[]; meta: MetaPagination } = await client
      .get("bulk_edits", {
        searchParams,
      })
      .json();

    return camelcaseKeys(
      { data: response.data || [], meta: response.meta },
      { deep: true },
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchBulkEdit(id: string | undefined): Promise<BulkEdit> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }

    const data: BulkEdit = await client.get(`bulk_edits/${id}`).json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postBulkEdit(bulkEditInput: BulkEditInput): Promise<BulkEdit> {
  try {
    if (typeof bulkEditInput.files === "undefined") {
      return Promise.reject(new Error("Invalid id"));
    }
    const formData = new FormData();
    formData.append("bulk_edit[file]", bulkEditInput.files[0]);

    const businessUnitId = bulkEditInput.businessUnitId
      ? bulkEditInput.businessUnitId.toString()
      : "";
    formData.append("bulk_edit[business_unit_id]", businessUnitId);

    formData.append("bulk_edit[model_type]", bulkEditInput.modelType);

    const data: BulkEdit = await client
      .post("bulk_edits", { body: formData })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function deleteBulkEdit(id: number): Promise<BulkEdit> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: BulkEdit = await client.delete(`bulk_edits/${id}`).json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({ businessUnit, order, orderBy, page }: QueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<BulkEdit>>({
      cacheTime: 0,
      queryKey: ["bulk_edits", businessUnit, order, orderBy, page],
      queryFn: () => fetchBulkEdits({ businessUnit, order, orderBy, page }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000, // 10 seconds
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<BulkEdit>({
      cacheTime: 0,
      queryKey: ["bulk_edit", id],
      queryFn: () => fetchBulkEdit(id),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (bulkEditInput: BulkEditInput) => {
    const navigate = useNavigate();
    return useMutation<BulkEdit>({
      mutationFn: () => postBulkEdit(bulkEditInput),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () => deleteBulkEdit(id),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
