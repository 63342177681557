import { Box, Button, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import MemberAPI from "../api/Member.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { AddressInput, MemberInput, ValueOf } from "../types";
import AddressForm from "./AddressForm";
import ArchiveButton from "./ArchiveButton";

type Props = {
  member: MemberInput;
  addressInput: AddressInput;
};

export default function MemberForm({ member, addressInput }: Props) {
  const navigate = useNavigate();

  const [facilityName, setFacilityName] = React.useState<string>(
    member.facilityName,
  );
  const [facilityId, setFacilityId] = React.useState<string>(member.facilityId);
  const [dea, setDea] = React.useState<string>(member.dea);
  const [hin, setHin] = React.useState<string>(member.hin);
  const [id340B, setId340B] = React.useState<string>(member.id340B);
  const [otherId, setOtherId] = React.useState<string>(member.otherId);

  const [classOfTrade, setClassOfTrade] = React.useState<string>(
    member.classOfTrade,
  );
  const [address, setAddress] = React.useState<AddressInput>(addressInput);

  const handleAddressChange = (
    addressKey: keyof AddressInput,
    addressValue: ValueOf<AddressInput>,
  ) => {
    setAddress({ ...address, [addressKey]: addressValue });
  };

  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { mutateAsync, isLoading } = MemberAPI.useSave({
    ...member,
    address,
    facilityName,
    facilityId,
    dea,
    hin,
    id340B,
    otherId,
    classOfTrade,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate("/members");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, {
        variant: "error",
      });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Facility Name"
          variant="outlined"
          value={facilityName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFacilityName(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Facility ID"
          value={facilityId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFacilityId(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="DEA"
          variant="outlined"
          value={dea}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDea(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="HIN"
          variant="outlined"
          value={hin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHin(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="340B"
          variant="outlined"
          value={id340B}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setId340B(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Other ID"
          variant="outlined"
          value={otherId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setOtherId(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Class of Trade"
          variant="outlined"
          value={classOfTrade}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setClassOfTrade(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          Address
        </Typography>
        <AddressForm
          address={address}
          handleAddressChange={handleAddressChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {member.id && <ArchiveMemberButton id={member.id} />}
      </Box>
    </Box>
  );
}

type ArchiveMemberButtonProps = {
  id: number;
};

function ArchiveMemberButton({ id }: ArchiveMemberButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = MemberAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/members");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
