import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { findIndex, isEmpty, isNil } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import BusinessUnitAPI from "../api/BusinessUnit.api";
import StatisticAPI from "../api/Statistic.api";
import WholesalerAPI from "../api/Wholesaler.api";
import logoWhiteImg from "../assets/logo-white.png";
import BusinessUnitLayout from "../components/BusinessUnitLayout";
import Loading from "../components/Loading";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { BusinessUnit, Wholesaler } from "../types";

export default function HomePage() {
  const { data, isLoading } = BusinessUnitAPI.useList();
  const { data: autoImportedFileGlobalCounts } =
    StatisticAPI.useAutoImportedFileGlobalCounts();
  const { data: chargebackDebitMemoGlobalCounts } =
    StatisticAPI.useChargebackDebitMemoGlobalCounts();
  const { data: chargebackFileGlobalCounts } =
    StatisticAPI.useChargebackFileGlobalCounts();

  return (
    <BusinessUnitLayout>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexGrow: 1,
          }}
        >
          {isNil(data) || isEmpty(data) ? (
            <Typography variant="h4" align="center">
              Onboard a business unit to get started.
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                flexGrow: 1,
                alignItems: "stretch",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 2,
                  gap: "1rem",
                  paddingTop: "1rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img
                    src={logoWhiteImg}
                    style={{ width: "100%", maxWidth: "24rem" }}
                    alt="Artemis CB"
                  />
                </Box>
                <ChargebackLinesByClientDateRange businessUnits={data} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <BoxTitleCount
                    title="Debit Memos in Investigation"
                    count={
                      chargebackDebitMemoGlobalCounts &&
                      chargebackDebitMemoGlobalCounts["investigationCount"]
                    }
                  />
                  <BoxTitleCount
                    title="Debit Memos that require Approval"
                    count={
                      chargebackDebitMemoGlobalCounts &&
                      chargebackDebitMemoGlobalCounts["requireApprovalCount"]
                    }
                  />
                  <BoxTitleCount
                    title="Approved Debit Memos this month"
                    count={
                      chargebackDebitMemoGlobalCounts &&
                      chargebackDebitMemoGlobalCounts["approvedCount"]
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: "1rem",
                  paddingTop: "1rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <Link to={"/onboard-business-unit"}>
                  <Button variant="contained" fullWidth sx={{ height: "4em" }}>
                    <Typography variant="body1">
                      Onboard a business unit
                    </Typography>
                  </Button>
                </Link>
                <SelectBusinessUnit businessUnits={data} />
                <BoxTitleCount
                  title="Auto Imported Files Today"
                  count={
                    autoImportedFileGlobalCounts &&
                    autoImportedFileGlobalCounts["filesImportedTodayCount"]
                  }
                />
                <BoxTitleCount
                  title="Auto Imported Files with Errors"
                  count={
                    autoImportedFileGlobalCounts &&
                    autoImportedFileGlobalCounts["errorCount"]
                  }
                />
                <BoxTitleCount
                  title="Files with Errors"
                  count={
                    chargebackFileGlobalCounts &&
                    chargebackFileGlobalCounts["errorCount"]
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </BusinessUnitLayout>
  );
}

function SelectBusinessUnit({
  businessUnits,
}: {
  businessUnits: BusinessUnit[];
}) {
  const navigate = useNavigate();

  const { setCurrentBusinessUnit } = useBusinessUnitContext();
  const handleChange = (event: SelectChangeEvent) => {
    const businessUnitIndex = findIndex(
      businessUnits,
      (bu) => bu.id.toString() === event.target.value.toString(),
    );
    setCurrentBusinessUnit &&
      setCurrentBusinessUnit(businessUnits[businessUnitIndex] as BusinessUnit);
    navigate("/dashboard");
  };
  return (
    <Paper>
      <FormControl fullWidth>
        <InputLabel id="select-business-unit-label">
          Access Business Unit
        </InputLabel>
        <Select
          labelId="select-business-unit-label"
          id="select-business-unit"
          label="Access Business Unit"
          onChange={handleChange}
          variant="outlined"
          // sx={{
          //   "&.MuiSelect-select": {
          //     backgroundColor: "#fff",
          //   },
          // }}
        >
          {businessUnits.map((businessUnit) => (
            <MenuItem key={businessUnit.id} value={businessUnit.id}>
              {businessUnit.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
}

function BoxTitleCount({ title, count }: { title: string; count?: number }) {
  return (
    <Paper sx={{ p: "1rem" }} elevation={1}>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="h4">{count}</Typography>
    </Paper>
  );
}

function ChargebackLinesByClientDateRange({
  businessUnits,
}: {
  businessUnits: BusinessUnit[];
}) {
  const [businessUnit, setBusinessUnit] = React.useState<
    BusinessUnit | undefined
  >();
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [wholesaler, setWholesaler] = React.useState<Wholesaler | undefined>(
    undefined,
  );
  const { data, isLoading } = StatisticAPI.useChargebackLineitems({
    businessUnitId: businessUnit?.id,
    wholesalerId: wholesaler ? wholesaler?.id : undefined,
    startDate: startDate ? startDate.format("YYYY-MM-DD") : startDate,
    endDate: endDate ? endDate.format("YYYY-MM-DD") : endDate,
  });

  const handleChange = (event: SelectChangeEvent) => {
    const businessUnitIndex = findIndex(
      businessUnits,
      (bu) => bu.id.toString() === event.target.value.toString(),
    );
    setBusinessUnit(businessUnits[businessUnitIndex] as BusinessUnit);
  };

  const chargebackLineitems = data
    ? Intl.NumberFormat("en-US").format(data["chargebackLineitems"])
    : isLoading
    ? ""
    : "Loading...";
  return (
    <Paper sx={{ p: "1rem", gap: "1rem" }} elevation={1}>
      <Typography variant="body1">Chargeback Lines</Typography>
      <Typography variant="h4">{chargebackLineitems}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          paddingTop: "1rem",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-business-unit-label">Business Unit</InputLabel>
          <Select
            labelId="select-business-unit-label"
            id="select-business-unit"
            label="Access Business Unit"
            onChange={handleChange}
            variant="outlined"
          >
            {businessUnits.map((businessUnit) => (
              <MenuItem key={businessUnit.id} value={businessUnit.id}>
                {businessUnit.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {businessUnit && (
          <WholesalerSelect
            businessUnit={businessUnit}
            setWholesaler={setWholesaler}
            wholesaler={wholesaler}
          />
        )}
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(value: any) => {
            setStartDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Paper>
  );
}

type WholesalerSelectProps = {
  businessUnit: BusinessUnit;
  setWholesaler: (arg?: Wholesaler) => void;
  wholesaler?: Wholesaler;
};
function WholesalerSelect({
  businessUnit,
  setWholesaler,
  wholesaler,
}: WholesalerSelectProps) {
  const { data: wholesalers } = WholesalerAPI.useAll({ businessUnit });
  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === undefined) {
      setWholesaler(undefined);
    } else {
      if (wholesalers) {
        const wholesalerIndex = findIndex(
          wholesalers,
          (wholesaler) =>
            wholesaler.id.toString() === event.target.value.toString(),
        );
        setWholesaler(wholesalers[wholesalerIndex] as Wholesaler);
      }
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="select-business-unit-label">Wholesaler</InputLabel>
      <Select
        labelId="select-business-unit-label"
        id="select-business-unit"
        label="Wholesaler"
        onChange={handleChange}
        variant="outlined"
      >
        <MenuItem key={0} value={undefined}>
          None
        </MenuItem>
        {wholesalers &&
          wholesalers.map((wholesaler) => (
            <MenuItem key={wholesaler.id} value={wholesaler.id}>
              {wholesaler.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
