import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ListResponseData,
  Order,
  Param,
  ProductWholesalerPrice,
  ProductWholesalerPriceInput,
  Wholesaler,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    wholesaler,
    order,
    orderBy,
    page,
    query,
  }: {
    wholesaler: Wholesaler;
    order: Order;
    orderBy: keyof ProductWholesalerPrice;
    query?: string;
    page?: number;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (wholesaler) {
      params.push({ key: "wholesaler_id", value: wholesaler.id });
    }
    return useQuery<ListResponseData<ProductWholesalerPrice>>({
      cacheTime: 0,
      queryKey: [
        "product_wholesaler_prices",
        wholesaler,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "product_wholesaler_prices",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ProductWholesalerPrice>({
      cacheTime: 0,
      queryKey: ["product_wholesaler_prices", id],
      queryFn: () => handleDetail({ id, baseUrl: "product_wholesaler_prices" }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (productWholesalerPriceInput: ProductWholesalerPriceInput) => {
    const navigate = useNavigate();
    return useMutation<ProductWholesalerPrice>({
      mutationFn: () =>
        handleSave({
          baseUrl: "product_wholesaler_prices",
          input: productWholesalerPriceInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () =>
        handleDelete({ id, baseUrl: "product_wholesaler_prices" }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
