import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ContractWholesalerEligibilityAPI from "../api/ContractWholesalerEligibility.api";
import { ContractWholesalerEligibilityInput } from "../types";

type Props = {
  contractId: number;
  wholesalerId: number;
  contractWholesalerEligibility: ContractWholesalerEligibilityInput;
  handleClose: () => void;
};

export default function ContractWholesalerEligibilityForm({
  contractId,
  wholesalerId,
  contractWholesalerEligibility,
  handleClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    contractWholesalerEligibility.effectiveDate
      ? dayjs(contractWholesalerEligibility.effectiveDate, "YYYY-MM-DD")
      : null,
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    contractWholesalerEligibility.endDate
      ? dayjs(contractWholesalerEligibility.endDate, "YYYY-MM-DD")
      : null,
  );

  const { mutateAsync, isLoading } = ContractWholesalerEligibilityAPI.useSave({
    ...contractWholesalerEligibility,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    contractId,
    wholesalerId,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Created successfully", { variant: "success" });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {contractWholesalerEligibility.id && (
          <DeleteButton
            id={contractWholesalerEligibility.id}
            handleClose={handleClose}
          />
        )}
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
}

type PropsDeleteButton = {
  id: number;
  handleClose: () => void;
};

function DeleteButton({ id, handleClose }: PropsDeleteButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } =
    ContractWholesalerEligibilityAPI.useDelete(id);

  const handleDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return (
    <Button
      variant="contained"
      color="error"
      onClick={throttledHandleDelete}
      disabled={isLoading}
    >
      Delete
    </Button>
  );
}
