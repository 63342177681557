import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  Contract,
  ContractInput,
  ListResponseData,
  Order,
  Param,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy: keyof Contract;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({ key: "business_unit_id", value: businessUnit.id });
    }
    return useQuery<ListResponseData<Contract>>({
      cacheTime: 0,
      queryKey: ["contracts", businessUnit, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "contracts",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Contract>({
      cacheTime: 0,
      queryKey: ["contracts", id],
      queryFn: () =>
        handleDetail({
          id,
          baseUrl: "contracts",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (contractInput: ContractInput) => {
    const navigate = useNavigate();
    return useMutation<Contract>({
      mutationFn: () =>
        handleSave({
          baseUrl: "contracts",
          input: contractInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "contracts",
          id,
        }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
