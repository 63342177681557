import { Button } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";

type Props = {
  id: number;
  refetch: () => void;
};

export default function ChargebackDebitMemoReinvestigateButton({
  id,
  refetch,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } =
    ChargebackDebitMemoAPI.useReinvestigate(id);

  const handleReinvestigate = async () => {
    try {
      await mutateAsync();
      refetch();
      enqueueSnackbar("Reinvestigating Debit Memo", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledHandleReinvestigate = React.useCallback(
    debounce(handleReinvestigate, 1000),
    [],
  );

  return (
    <Button
      onClick={throttledHandleReinvestigate}
      color="secondary"
      variant={"contained"}
      disabled={isLoading}
    >
      Reinvestigate
    </Button>
  );
}
