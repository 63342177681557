import { Box, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import WholesalerAPI from "../api/Wholesaler.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { WholesalerInput } from "../types";
import ArchiveButton from "./ArchiveButton";

type Props = {
  wholesaler: WholesalerInput;
  children?: JSX.Element;
};

export default function WholesalerForm({ wholesaler, children }: Props) {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>(wholesaler.name || "");
  const [dea, setDea] = React.useState<string>(wholesaler.dea || "");
  const [hin, setHin] = React.useState<string>(wholesaler.hin || "");
  const [otherId, setOtherId] = React.useState<string>(
    wholesaler.otherId || "",
  );
  const [customerId, setCustomerId] = React.useState<string>(
    wholesaler?.customerId || "",
  );

  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { mutateAsync, isLoading } = WholesalerAPI.useSave({
    ...wholesaler,
    name,
    dea,
    hin,
    otherId,
    customerId,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate(`/wholesalers/${res.id}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
        sx={{ marginBottom: "1rem" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="DEA"
          variant="outlined"
          value={dea}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDea(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="HIN"
          variant="outlined"
          value={hin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHin(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Other ID"
          variant="outlined"
          value={otherId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setOtherId(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Customer ID"
          variant="outlined"
          value={customerId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerId(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box>{children}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {wholesaler.id && <ArchiveWholesalerButton id={wholesaler.id} />}
      </Box>
    </Box>
  );
}

type ArchiveWholesalerButtonProps = {
  id: number;
};

function ArchiveWholesalerButton({ id }: ArchiveWholesalerButtonProps) {
  const navigate = useNavigate();

  const { mutate } = WholesalerAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutate();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/wholesalers");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
