import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  ListResponseData,
  Order,
  Param,
  Product,
  ProductInput,
  ProductSearchQueryProps,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

async function searchProducts({
  businessUnit,
  query,
}: ProductSearchQueryProps): Promise<Product[]> {
  try {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("query", query);
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    const data: Product[] = await client
      .get("products/search", {
        searchParams,
      })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy: keyof Product;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({ key: "business_unit_id", value: businessUnit.id });
    }
    return useQuery<ListResponseData<Product>>({
      cacheTime: 0,
      queryKey: ["products", businessUnit, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "products",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSearch: ({ businessUnit, query }: ProductSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Product[]>({
      queryKey: ["products", businessUnit, query],
      queryFn: () => searchProducts({ businessUnit, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Product, Error>({
      cacheTime: 0,
      queryKey: ["products", id],
      queryFn: () => handleDetail({ id, baseUrl: "products" }),
      enabled: Boolean(id),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (productInput: ProductInput) => {
    const navigate = useNavigate();
    return useMutation<Product>({
      mutationFn: () =>
        handleSave({ baseUrl: "products", input: productInput }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "products", id }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
