import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  Group,
  GroupInput,
  GroupSearchQueryProps,
  ListResponseData,
  Order,
  Param,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

async function searchGroups({
  businessUnit,
  query,
}: GroupSearchQueryProps): Promise<Group[]> {
  try {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("query", query);
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    const data: Group[] = await client
      .get("groups/search", {
        searchParams,
      })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy: keyof Group;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({ key: "business_unit_id", value: businessUnit.id });
    }
    return useQuery<ListResponseData<Group>>({
      cacheTime: 0,
      queryKey: ["groups", businessUnit, order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "groups", params, order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useSearch: ({ businessUnit, query }: GroupSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Group[]>({
      queryKey: ["groups", businessUnit, query],
      queryFn: () => searchGroups({ businessUnit, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Group>({
      cacheTime: 0,
      queryKey: ["groups", id],
      queryFn: () =>
        handleDetail({
          id,
          baseUrl: "groups",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (groupInput: GroupInput) => {
    const navigate = useNavigate();
    return useMutation<Group>({
      mutationFn: () => handleSave({ baseUrl: "groups", input: groupInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      onError: handleQueryError(navigate),
      mutationFn: () => handleDelete({ id, baseUrl: "groups" }),
    });
  },
};

export default methods;
