import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Box, IconButton, TableCell, Typography} from "@mui/material";
import {startCase} from "lodash";
import React from "react";
import CurrencyFormat from "react-currency-format";

import ProductWacPriceAPI from "../api/ProductWacPrice.api";
import {Order, Product, ProductWacPrice, TableColumn} from "../types";
import {extractDate} from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";
import ProductWacPriceModal from "./ProductWacPriceModal";

const columns: TableColumn<ProductWacPrice>[] = [
  {field: "priceCents", label: "Price"},
  {field: "priceClassification", label: "Price Classification"},
  {field: "effectiveDate", label: "Effective Date"},
  {field: "endDate", label: "End Date"},
];

function buildTableRow(productWacPrice: ProductWacPrice): JSX.Element[] {
  return [
    <TableCell key={"priceCents"}>
      <CurrencyFormat
        value={productWacPrice.price}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </TableCell>,
    <TableCell key={"priceClassification"}>
      {startCase(productWacPrice.priceClassification)}
    </TableCell>,
    <TableCell key={"effectiveDate"}>
      {extractDate(productWacPrice.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(productWacPrice.endDate)}
    </TableCell>,
  ];
}

type Props = {
  product: Product;
};

export default function ProductWacPriceManagement({
                                                    product,

                                                  }: Props) {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ProductWacPrice>("endDate");

  const [productWacId, setProductWacId] = React.useState<number>();

  const handleProductWacPriceClick = (showProductWacId: number) => {
    setProductWacId(showProductWacId);
  };

  const {data, refetch} = ProductWacPriceAPI.useList({
    product,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ProductWacPrice,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (productWacPrice: ProductWacPrice) => (event: unknown) => {
      handleProductWacPriceClick(productWacPrice.id);
      setOpen(true)
    };

  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  const handleOpen = () => {
    setProductWacId(undefined);
    setOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">WAC Prices</Typography>
        <IconButton
          aria-label="Create a Distribution Center"
          size="large"
          onClick={handleOpen}
        >
          <AddCircleIcon color="primary"/>
        </IconButton>
      </Box>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading/>
      )}

      <ProductWacPriceModal
        id={productWacId}
        productId={product.id}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
}
