import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import BulkEditAPI from "../api/BulkEdit.api";
import Layout from "../components/Layout";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { BulkEditUploadModelType } from "../types";

// TODO
// contract wholesaler eligibilities
// group member eligibilities

const sampleFiles = {
  contract:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edits-contract.xlsx",
  contract_product_price:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-contract-product-price.xlsx",
  group:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-group.xlsx",
  member:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-member.xlsx",
  product:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-product.xlsx",
  product_wac_price:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-product-wac-price.xlsx",
  product_wholesaler_price:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-product-wholesaler-price.xlsx",
  wholesaler:
    "https://prescription-analytic-files.s3.us-west-2.amazonaws.com/bulk-edit-wholesaler.xlsx",
};

export default function BulkEditUpload() {
  const navigate = useNavigate();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = React.useState<BulkEditUploadModelType>("contract");

  const [files, setFiles] = React.useState<any[]>();

  const onDrop = React.useCallback(async (acceptedFiles: any[]) => {
    if (acceptedFiles.length === 1) {
      setFiles(acceptedFiles);
    } else {
      enqueueSnackbar("Only one file is permitted.", { variant: "error" });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "text/csv": [".csv"],
        // "application/vnd.ms-excel": [".xls", ".xlt", ".xla"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    });

  const { isLoading, mutateAsync } = BulkEditAPI.useSave({
    files,
    modelType: model,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate(`/bulk-edits`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  if (fileRejections.length > 0) {
    fileRejections.forEach(({ file, errors }) => {
      enqueueSnackbar(`Failed to upload file: ${errors[0].message}`, {
        variant: "error",
      });
    });
  }

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Bulk Edit Upload</Typography>

        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              value={model}
              label="Model"
              onChange={(e: SelectChangeEvent) => {
                setModel(e.target.value as BulkEditUploadModelType);
              }}
            >
              <MenuItem value={"contract"}>Contract</MenuItem>
              <MenuItem value={"contract_product_price"}>
                Contract Product Price
              </MenuItem>
              <MenuItem value={"group"}>Group</MenuItem>
              <MenuItem value={"member"}>Member</MenuItem>
              <MenuItem value={"product"}>Product</MenuItem>
              <MenuItem value={"product_wac_price"}>Product WAC Price</MenuItem>
              <MenuItem value={"product_wholesaler_price"}>
                Product Wholesaler Price
              </MenuItem>
              <MenuItem value={"wholesaler"}>Wholesaler</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {model && sampleFiles[model] && (
          <Box sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
            <a
              href={sampleFiles[model]}
              download="Example-Excel-File"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Download sample excel file</Button>
            </a>
          </Box>
        )}

        <Box>
          <Paper
            elevation={3}
            sx={{ padding: "2rem", justifyContent: "center", display: "flex" }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <BoxText
              isDragActive={isDragActive}
              fileLoaded={!!files}
              fileName={
                files && files.length > 0
                  ? files.map((f) => f.name).join(", ")
                  : ""
              }
            />
          </Paper>
        </Box>

        {!!files && (
          <Button
            fullWidth
            onClick={throttledOnSave}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            UPLOAD
          </Button>
        )}
      </Box>
    </Layout>
  );
}

type PropsBoxText = {
  isDragActive: boolean;
  fileLoaded: boolean;
  fileName?: string;
};
function BoxText({ isDragActive, fileLoaded, fileName }: PropsBoxText) {
  if (isDragActive) {
    return <p>Drop the file here ...</p>;
  } else if (fileLoaded) {
    return (
      <p>
        File uploaded: <strong>{fileName}</strong>
      </p>
    );
  }
  return <p>Drag 'n' drop a file here, or click to select the file</p>;
}
