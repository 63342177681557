import {Box, Typography} from "@mui/material";
import React from "react";
import {useParams} from "react-router-dom";

import ProductAPI from "../api/Product.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import ProductForm from "../components/ProductForm";
import ProductWacPriceManagement from "../components/ProductWacPriceManagement";

export default function ProductEdit() {
  const {id} = useParams();
  const {data, isSuccess, isLoading, isError} = ProductAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Product Edit</Typography>

        {isLoading || isError ? (
          <Loading/>
        ) : (
          data &&
          isSuccess && (
            <ProductForm product={data}>
              <ProductWacPriceManagement
                product={data}
              />
            </ProductForm>
          )
        )}
      </Box>
    </Layout>
  );
}
