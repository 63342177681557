import { Box, Button, TextField } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

// import WholesalerDistributionCenterTable from "./WholesalerDistributionCenterTable";
import ProductAPI from "../api/Product.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ProductInput } from "../types";
import ArchiveButton from "./ArchiveButton";

type Props = {
  product: ProductInput;
  children?: JSX.Element;
};

export default function ProductForm({ product, children }: Props) {
  const [name, setName] = React.useState<string>(product.name);
  const [ndc, setNdc] = React.useState<string>(product.ndc);
  const [upc, setUpc] = React.useState<string>(product.upc);
  const [gtin, setGtin] = React.useState<string>(product.gtin);
  const [wholesalerProductNumber, setWholesalerProductNumber] =
    React.useState<string>(product.wholesalerProductNumber);
  const [wholesaler, setWholesaler] = React.useState<string>(
    product.wholesaler,
  );
  const [genericName, setGenericName] = React.useState<string>(
    product.genericName,
  );
  const [strength, setStrength] = React.useState<string>(product.strength);
  const [productFamily, setProductFamily] = React.useState<string>(
    product.productFamily,
  );
  const [color, setColor] = React.useState<string>(product.color);
  const [shape, setShape] = React.useState<string>(product.shape);
  const [productType, setProductType] = React.useState<string>(
    product.productType,
  );
  const [caseQuantity, setCaseQuantity] = React.useState<number | undefined>(
    product.caseQuantity,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();

  const { mutateAsync, isLoading } = ProductAPI.useSave({
    ...product,
    caseQuantity,
    name,
    ndc,
    upc,
    gtin,
    wholesalerProductNumber,
    wholesaler,
    genericName,
    strength,
    productFamily,
    color,
    shape,
    productType,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
    } catch (error) {
      console.error("onSave", error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="NDC"
          variant="outlined"
          value={ndc}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNdc(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Product Type"
          variant="outlined"
          value={productType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProductType(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="UPC"
          variant="outlined"
          value={upc}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUpc(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="GTIN"
          variant="outlined"
          value={gtin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setGtin(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Wholesaler Product Number"
          variant="outlined"
          value={wholesalerProductNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWholesalerProductNumber(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Generic Name"
          variant="outlined"
          value={genericName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setGenericName(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Wholesaler"
          variant="outlined"
          value={wholesaler}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWholesaler(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Strength"
          variant="outlined"
          value={strength}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setStrength(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Product Family"
          variant="outlined"
          value={productFamily}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProductFamily(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Color"
          variant="outlined"
          value={color}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setColor(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Shape"
          variant="outlined"
          value={shape}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setShape(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Case Quantity"
          variant="outlined"
          value={caseQuantity}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const val = event.target.value;
            if (isEmpty(val)) {
              setCaseQuantity(undefined);
            } else {
              setCaseQuantity(parseInt(val));
            }
          }}
          fullWidth
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />
      </Box>
      <Box>{children}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          fullWidth={!product.id}
          disabled={isLoading}
        >
          Save
        </Button>
        {product.id && <ArchiveProductButton id={product.id} />}
      </Box>
    </Box>
  );
}

type ArchiveProductButtonProps = {
  id: number;
};

function ArchiveProductButton({ id }: ArchiveProductButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = ProductAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/products");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
