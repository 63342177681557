import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Wholesaler } from "../types";
import WholesalerSearchBarAutocomplete from "./WholesalerSearchBarAutocomplete";

export default function ExportListSearch() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();

  const [effectiveDate, setEffectiveDate] = React.useState(
    dayjs(null, "YYYY-MM-DD", true),
  );
  const [endDate, setEndDate] = React.useState(dayjs(null, "YYYY-MM-DD", true));
  const [currentWholesaler, setCurrentWholesaler] =
    React.useState<Wholesaler>();

  const { mutateAsync, isLoading } = ChargebackDebitMemoAPI.useExportSearch({
    businessUnit: currentBusinessUnit,
    wholesalerId: currentWholesaler && currentWholesaler.id,
    effectiveDate: effectiveDate.format("DD/MM/YYYY"),
    endDate: endDate.format("DD/MM/YYYY"),
  });

  const handleExportSearch = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/exports/${res["id"]}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  const throttledExportSearch = React.useCallback(
    debounce(handleExportSearch, 1000),
    [],
  );

  return (
    <Box maxWidth="sm">
      <WholesalerSearchBarAutocomplete
        setCurrentWholesaler={(wholesaler) => {
          setCurrentWholesaler(wholesaler);
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box>
        <Button
          disabled={isLoading}
          onClick={throttledExportSearch}
          variant="contained"
        >
          Run Report
        </Button>
      </Box>
    </Box>
  );
}
