import ky from "ky";
import { isArray, isEmpty, isNil } from "lodash";

import { HttpError } from "../types/http-error";

const client = ky.extend({
  retry: 0,
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  hooks: {
    beforeRequest: [
      (request) => {
        const data = window.localStorage.getItem(
          process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        );
        if (!(isNil(data) || isEmpty(data))) {
          const sessionData = JSON.parse(data) || {};
          const expDate = new Date(sessionData["exp"]);
          if (
            sessionData["token"] &&
            sessionData["exp"] &&
            expDate > new Date()
          ) {
            request.headers.set(
              "Authorization",
              `Bearer ${sessionData["token"]}`,
            );
          }
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response && response.status === 401) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 413) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 422) {
          const errorJson = await response.json();
          if (isArray(errorJson["errors"])) {
            throw new HttpError(response.status, errorJson["errors"][0]);
          }
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status >= 400) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        }
      },
    ],
  },
});

export default client;
