import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import ContractAPI from "../api/Contract.api";
import GroupAPI from "../api/Group.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ContractInput, Group } from "../types";
import ArchiveButton from "./ArchiveButton";
import ContractProductManagement from "./ContractProductManagement";
import ContractRefNumCrud from "./ContractRefNumCrud";
import ContractWholesalerManagement from "./ContractWholesalerManagement";
import GroupSearchBarAutocomplete from "./GroupSearchBarAutocomplete";
import Loading from "./Loading";

type Props = {
  contract: ContractInput;
};

export default function ContractForm({ contract }: Props) {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>(contract.name);

  const [contractType, setContractType] = React.useState<string>(
    contract.contractType || "indirect",
  );
  const [notificationInstructions, setNotificationInstructions] =
    React.useState<string>(contract.notificationInstructions);
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    contract.effectiveDate ? dayjs(contract.effectiveDate) : null,
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    contract.endDate ? dayjs(contract.endDate) : null,
  );
  const [allWholesalers, setAllWholesalers] = React.useState(
    contract.allWholesalers,
  );
  const [noMemberCheck, setNoMemberCheck] = React.useState(
    contract.noMemberCheck,
  );
  const [groupId, setGroupId] = React.useState(contract.groupId);

  const handleAllWholesalersCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAllWholesalers(event.target.checked);
  };

  const handleNoMemberCheckCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoMemberCheck(event.target.checked);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();

  const { mutateAsync, isLoading } = ContractAPI.useSave({
    ...contract,
    groupId,
    name,
    notificationInstructions,
    contractType,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    allWholesalers,
    noMemberCheck,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      if (res?.id) {
        navigate(`/contracts/${res.id}`);
      } else {
        navigate(`/contracts`);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <GroupContractEdit
          groupId={groupId ? groupId.toString() : undefined}
          setGroupId={setGroupId}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        {contract.id && <ContractRefNumCrud contractId={contract.id} />}
      </Box>
      <TextField
        label="Notification Instructions"
        multiline
        rows={4}
        value={notificationInstructions}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNotificationInstructions(event.target.value);
        }}
        sx={{ marginBottom: "1rem" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="contract-type-label">Type</InputLabel>
          <Select
            labelId="contract-type-lablel"
            id="contract-type-select"
            value={contractType.toString()}
            label="Type"
            onChange={(event: SelectChangeEvent) =>
              setContractType(event.target.value)
            }
          >
            <MenuItem value={"indirect"}>Indirect</MenuItem>
            {/*<MenuItem value={"direct"}>Direct</MenuItem>*/}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={noMemberCheck}
                onChange={handleNoMemberCheckCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="No member check"
          />
        </FormControl>
      </Box>
      {contract.id && (
        <>
          <ContractProductManagement contractId={contract.id} />

          <ContractWholesalerManagement
            showSelectedWholesalers={!allWholesalers}
            contractId={contract.id}
          >
            <Box sx={{ marginBottom: "1rem", marginTop: "0rem" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allWholesalers}
                      onChange={handleAllWholesalersCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="All Wholesalers"
                />
              </FormGroup>
            </Box>
          </ContractWholesalerManagement>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {contract.id && <ArchiveContractButton id={contract.id} />}
      </Box>
    </Box>
  );
}

type ArchiveContractButtonProps = {
  id: number;
};

function ArchiveContractButton({ id }: ArchiveContractButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = ContractAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/contracts");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnDelete = React.useCallback(debounce(onDelete, 1000), []);

  return <ArchiveButton onDelete={throttledOnDelete} />;
}

type PropsGroupContractEdit = {
  groupId: string | undefined;
  setGroupId: (id: number) => void;
};

function GroupContractEdit({ groupId, setGroupId }: PropsGroupContractEdit) {
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const { data } = GroupAPI.useDetail(groupId);

  const handleSetCurrentGroup = (group: Group) => {
    setGroupId(group.id);
    setShowEdit(false);
  };

  if (groupId && !showEdit) {
    if (data) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            gap: "0.5rem",
          }}
        >
          <TextField
            label="Group Name"
            value={data.name || ""}
            disabled
            fullWidth
          />
          <Button variant="contained" onClick={() => setShowEdit(true)}>
            Edit
          </Button>
        </Box>
      );
    }
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <GroupSearchBarAutocomplete setCurrentGroup={handleSetCurrentGroup} />
    </Box>
  );
}
