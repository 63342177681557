import { Button } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";

type Props = {
  id: number;
  refetch: () => void;
};

export default function ChargebackDebitMemoApproveButton({
  id,
  refetch,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = ChargebackDebitMemoAPI.useApprove(id);

  const handleApprove = async () => {
    try {
      await mutateAsync();
      refetch();
      enqueueSnackbar("Approved", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  const throttledHandleApprove = React.useCallback(
    debounce(handleApprove, 1000),
    [],
  );

  return (
    <Button
      variant="contained"
      onClick={throttledHandleApprove}
      disabled={isLoading}
    >
      Approve
    </Button>
  );
}
