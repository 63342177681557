import { Box, Button, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import ChargebackExportGroupAPI from "../api/ChargebackExportGroup.api";
import ArchiveButton from "../components/ArchiveButton";
import Layout from "../components/Layout";
import ChargebackExportGroupModel from "../models/ChargebackExportGroup.model";

// TODO: show errors if exist
export default function ChargebackExportGroupShow() {
  const { id } = useParams();
  const { data, refetch } = ChargebackExportGroupAPI.useDetail(id);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Export File Status</Typography>
        {data && (
          <Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="State"
                variant="standard"
                value={ChargebackExportGroupModel.stateName(data.state)}
                fullWidth
              />
            </Box>
            {data.state === "completed" && (
              <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <Link target="_blank" to={data.file}>
                  Download File
                </Link>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
                {data && (
                  <ReprocessChargebackExportGroupButton
                    id={data.id}
                    refetch={refetch}
                  />
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
                {data && <ArchiveChargebackExportGroupButton id={data.id} />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

function ReprocessChargebackExportGroupButton({
  id,
  refetch,
}: {
  id: number;
  refetch: () => void;
}) {
  const { mutateAsync } = ChargebackExportGroupAPI.useReprocess(id);

  const onReprocess = async () => {
    try {
      await mutateAsync();
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button onClick={onReprocess} variant="contained" color="primary">
      Rerun
    </Button>
  );
}

type ArchiveChargebackExportGroupButtonProps = {
  id: number;
};

function ArchiveChargebackExportGroupButton({
  id,
}: ArchiveChargebackExportGroupButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = ChargebackExportGroupAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/chargebacks/files");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
