import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ProductWholesalerPriceAPI from "../api/ProductWholesalerPrice.api";
import { PriceClassification, ProductWholesalerPriceInput } from "../types";
import CurrencyTextField from "./CurrencyTextField";
import PriceClassificationSelector from "./PriceClassificationSelector";

type Props = {
  wholesalerId: number;
  productId: number;
  productWholesalerPrice: ProductWholesalerPriceInput;
  handleClose: () => void;
};

export default function ProductWholesalerPriceForm({
  wholesalerId,
  productId,
  productWholesalerPrice,
  handleClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [priceClassification, setPriceClassification] =
    React.useState<PriceClassification>(
      productWholesalerPrice.priceClassification || "unit",
    );
  const [price, setPrice] = React.useState(productWholesalerPrice.price);
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    productWholesalerPrice.effectiveDate
      ? dayjs(productWholesalerPrice.effectiveDate, "YYYY-MM-DD")
      : null,
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    productWholesalerPrice.endDate
      ? dayjs(productWholesalerPrice.endDate, "YYYY-MM-DD")
      : null,
  );

  const { mutateAsync, isLoading } = ProductWholesalerPriceAPI.useSave({
    ...productWholesalerPrice,
    priceCents: Math.round(parseFloat(price) * 100),
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    priceClassification,
    productId,
    wholesalerId,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CurrencyTextField
          fullWidth={true}
          variant="outlined"
          label="Amount"
          value={price}
          setValue={(value) => setPrice(value)}
        />
        <PriceClassificationSelector
          priceClassification={priceClassification}
          setPriceClassification={setPriceClassification}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {productWholesalerPrice.id && (
          <DeleteButton
            id={productWholesalerPrice.id}
            handleClose={handleClose}
          />
        )}
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
}

type PropsDeleteButton = {
  id: number;
  handleClose: () => void;
};

function DeleteButton({ id, handleClose }: PropsDeleteButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = ProductWholesalerPriceAPI.useDelete(id);

  const handleDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return (
    <Button
      variant="contained"
      color="error"
      onClick={throttledHandleDelete}
      disabled={isLoading}
    >
      Delete
    </Button>
  );
}
