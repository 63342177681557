import { Box, Container, Paper } from "@mui/material";
import React from "react";

import NavigationBar from "./NavigationBar";
import NavigationMenu from "./NavigationMenu";

type Props = {
  children: JSX.Element;
  backgroundColor?: string;
};

export default function Layout({ children, backgroundColor }: Props) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <NavigationMenu />
      <Paper sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <NavigationBar />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            paddingBottom: "1rem",
            backgroundColor,
            px: "2rem",
          }}
        >
          {children}
        </Box>
      </Paper>
    </Box>
  );
}
