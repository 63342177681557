import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { toNumber } from "lodash";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import WholesalerDistributionCenterAPI from "../api/WholesalerDistributionCenter.api";
import {
  AddressInput,
  ValueOf,
  WholesalerDistributionCenterInput,
} from "../types";
import AddressForm from "./AddressForm";
import ArchiveButton from "./ArchiveButton";

type Props = {
  addressInput: AddressInput;
  wholesalerDistributionCenter: WholesalerDistributionCenterInput;
  wholesalerId: string;
};

export default function WholesalerDistributionCenterForm({
  addressInput,
  wholesalerDistributionCenter,
  wholesalerId,
}: Props) {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>(
    wholesalerDistributionCenter.name || "",
  );
  const [dea, setDea] = React.useState<string>(
    wholesalerDistributionCenter.dea || "",
  );
  const [hin, setHin] = React.useState<string>(
    wholesalerDistributionCenter.hin || "",
  );
  const [dcId, setDcId] = React.useState<string>(
    wholesalerDistributionCenter.dcId || "",
  );
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    wholesalerDistributionCenter.effectiveDate
      ? dayjs(wholesalerDistributionCenter.effectiveDate, "YYYY-MM-DD")
      : null,
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    wholesalerDistributionCenter.endDate
      ? dayjs(wholesalerDistributionCenter.endDate, "YYYY-MM-DD")
      : null,
  );
  const [address, setAddress] = React.useState<AddressInput>(addressInput);

  const handleAddressChange = (
    addressKey: keyof AddressInput,
    addressValue: ValueOf<AddressInput>,
  ) => {
    setAddress({ ...address, [addressKey]: addressValue });
  };

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } = WholesalerDistributionCenterAPI.useSave({
    ...wholesalerDistributionCenter,
    address,
    name,
    dea,
    hin,
    dcId,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    wholesalerId: toNumber(wholesalerId),
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate(`/wholesalers/${wholesalerId}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="DC ID"
          variant="outlined"
          value={dcId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDcId(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="DEA"
          variant="outlined"
          value={dea}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDea(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="HIN"
          variant="outlined"
          value={hin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHin(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          Address
        </Typography>
        <AddressForm
          address={address}
          handleAddressChange={handleAddressChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {wholesalerDistributionCenter.id && (
          <ArchiveWholesalerDistributionCenterButton
            wholesalerId={wholesalerId}
            id={wholesalerDistributionCenter.id}
          />
        )}
      </Box>
    </Box>
  );
}

type ArchiveWholesalerDistributionCenterButtonProps = {
  id: number;
  wholesalerId: string;
};

function ArchiveWholesalerDistributionCenterButton({
  id,
  wholesalerId,
}: ArchiveWholesalerDistributionCenterButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = WholesalerDistributionCenterAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate(`/wholesalers/${wholesalerId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
