import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ContractWholesalerEligibility,
  ContractWholesalerEligibilityInput,
  ListResponseData,
  Order,
  Param,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    contractId,
    order,
    orderBy,
    page,
    query,
  }: {
    contractId: number;
    order: Order;
    orderBy: keyof ContractWholesalerEligibility;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (contractId) {
      params.push({ key: "contract_id", value: contractId });
    }
    return useQuery<ListResponseData<ContractWholesalerEligibility>>({
      cacheTime: 0,
      queryKey: [
        "contract_wholesaler_eligibilities",
        contractId,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "contract_wholesaler_eligibilities",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ContractWholesalerEligibility>({
      cacheTime: 0,
      queryKey: ["contract_wholesaler_eligibilities", id],
      queryFn: () =>
        handleDetail({
          id,
          baseUrl: "contract_wholesaler_eligibilities",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (
    contractWholesalerEligibilityInput: ContractWholesalerEligibilityInput,
  ) => {
    const navigate = useNavigate();
    return useMutation<ContractWholesalerEligibility>({
      mutationFn: () =>
        handleSave({
          baseUrl: "contract_wholesaler_eligibilities",
          input: contractWholesalerEligibilityInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "contract_wholesaler_eligibilities",
          id,
        }),
      onError: handleQueryError(navigate),
    });
  },
};
export default methods;
